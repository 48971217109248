import * as React from 'react';

import * as BoxStyles from './BoxStyles';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ErrorDialog = (props) => {
    const { onClose, open, errorData } = props;

    const errorTitle = (errorData.title || 'ERROR').trim();

    return (
        <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md" sx={BoxStyles.dialogBoxStyle}>
            <DialogTitle sx={BoxStyles.dialogTitleStyle(true)}>
                <Box sx={BoxStyles.alignDialogTitleStyle}>
                    <Box sx={BoxStyles.inlineBoxStyle}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {errorTitle.split(' ')[0]}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontWeight: 'normal', marginLeft: '8px' }}>
                        {errorTitle.split(' ').slice(1).join(' ')}
                    </Typography>
                </Box>
                <IconButton onClick={onClose} sx={BoxStyles.iconStyle}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box sx={{ ...BoxStyles.dialogContentStyle, ...BoxStyles.errorMessageStyle }}>
                {errorData.error}
            </Box>
        </Dialog>
    );
};

export default ErrorDialog;