import React from "react";

import * as auth from "./authentication/Auth";
import * as BoxStyles from './BoxStyles';

import EnvironmentBox from "./EnvironmentBox";
import AppConfigDialog from "./AppConfigDialog";
import ErrorDialog from "./ErrorDialog";

import {Box} from "@mui/material";

const authToken = auth.getIdToken();

function AppContainer() {
    const [appConfigOpen, setAppConfigOpen] = React.useState(false);
    const [selectedConfigValue, setSelectedConfigValue] = React.useState({});

    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
    const [errorData, setErrorData] = React.useState({});

    const handleClose = () => {
        setAppConfigOpen(false)
        setErrorDialogOpen(false)
    };

    const envBoxProps = {
        token: authToken,
        setErrorDialogOpen: setErrorDialogOpen,
        setErrorData: setErrorData,
        setAppConfigOpen: setAppConfigOpen,
        setSelectedConfigValue: setSelectedConfigValue
    }

    return (
        <div className="App">
            <h1 style={BoxStyles.dashboardStyle}>
                Dashboard
                <span style={BoxStyles.gradientShadowStyle}></span>
                <span style={BoxStyles.cerberusBoxStyle}>CERBERUS</span>
            </h1>
            <h2 style={{ marginLeft: "25px" }}>Americas (AMR)</h2>
            <Box style={BoxStyles.containerStyle}>
                <EnvironmentBox url={`${process.env.REACT_APP_AMR_DEV_BASE_API_URL}/info`} env="amr_dev" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_AMR_QA_BASE_API_URL}/info`} env="amr_qa" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_AMR_STG_BASE_API_URL}/info`} env="amr_stg" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_AMR_DEMO_BASE_API_URL}/info`} env="amr_demo" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_AMR_PRD_BASE_API_URL}/info`} env="amr_prd" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_MFR_AMR_DEV_BASE_API_URL}/info`} env="amr_mfr_dev" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_MFR_AMR_PRD_BASE_API_URL}/info`} env="amr_mfr_prd" {...envBoxProps} />
            </Box>

            <h2 style={{ marginLeft: "25px" }}>Europe (EU)</h2>
            <Box style={{ ...BoxStyles.containerStyle, marginBottom: "20px" }}>
                <EnvironmentBox url={`${process.env.REACT_APP_EU_DEV_BASE_API_URL}/info`} env="eu_dev" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_EU_DEMO_BASE_API_URL}/info`} env="eu_demo" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_EU_PRD_BASE_API_URL}/info`} env="eu_prd" {...envBoxProps} />
                <EnvironmentBox url={`${process.env.REACT_APP_MFR_EU_PRD_BASE_API_URL}/info`} env="eu_mfr_prd" {...envBoxProps} />
            </Box>

            <AppConfigDialog open={appConfigOpen} onClose={handleClose} appConfigData={selectedConfigValue} />
            <ErrorDialog open={errorDialogOpen} onClose={handleClose} errorData={errorData} />
        </div>
    );
}

export default AppContainer;