// Layout and Positioning Styles
export const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "20px",
    marginLeft: "10px"
};

export const dashboardStyle = {
    position: "relative",
    paddingLeft: "20px",
    paddingTop: "30px",
    marginBottom: "40px",
    display: "flex",
    alignItems: "center"
};

export const centeredStyle = {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center'
};

export const bottomLabelStyle = {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
    paddingTop: '5px',
    ...centeredStyle
};

// Box and Container Styles
export const cerberusBoxStyle = {
    display: "inline-block",
    border: "2px solid #212222",
    padding: "2px 5px",
    borderRadius: "4px",
    marginLeft: "20px",
    marginTop: "4px",
    fontSize: '14px'
};

export const versionStyle = {
    width: '275px',
    minHeight: '229px',
    borderRadius: '16px',
    position: 'relative',
    margin: '15px',
    color: '#212222',
    backgroundColor: '#efefef',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)'
};

export const erroredStyle = {
    ...versionStyle
};

export const inlineBoxStyle = {
    display: 'inline-block',
    border: '2px solid #212222',
    padding: '2px 5px',
    borderRadius: '4px',
    marginRight: '8px',
    fontSize: '20px',
    fontWeight: 'bold',
    textTransform: 'uppercase'
};

// Dialog and Title Styles
export const dialogBoxStyle = {
    borderRadius: '24px',
    padding: '30px',
    '& .MuiDialog-paper': {
        borderRadius: '24px',
        backgroundColor: '#efefef'
    }
};

export const dialogContentStyle = {
    fontSize: '14px',
    borderRadius: '20px',
    overflowY: 'auto',
    overflowX: 'auto',
    padding: '20px',
    paddingBottom: '20px',
    maxHeight: '60vh',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    margin: "10px"
};

export const alignDialogTitleStyle = {
    display: 'flex',
    alignItems: 'center'
};

export const dialogTitleStyle = (isError) => ({
    fontSize: '26px',
    fontWeight: 'bold',
    color: '#212222',
    padding: '25px 40px 25px',
    backgroundColor: isError ? '#E53935' : '#43A047',
    borderBottom: `5px solid ${isError ? '#C62828' : '#2E7D32'}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

export const titleBackgroundStyle = (isOperational) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    borderBottom: `5px solid ${isOperational ? '#2E7D32' : '#C62828'}`,
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px'
});

export const loadingTitleBackgroundStyle = {
    backgroundColor: '#FDD835',
    display: 'flex',
    padding: '16px',
    borderBottom: '5px solid #FBC02D',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px'
};

// Button and Link Styles
export const buttonStyle = {
    position: 'absolute',
    fontWeight: 'bold',
    color: '#E6EFE5',
    backgroundColor: '#2E7D32',
    border: '2px solid transparent',
    bottom: '45%',
    right: '32.5%',
    boxShadow: 'none'
};

export const errorButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#C62828'
};

export const linkStyle = {
    textDecoration: 'none',
    color: 'white'
};

// Text and Icon Styles
export const iconStyle = {
    color: '#212222'
};

export const versionTextStyle = {
    fontWeight: '500',
    color: '#212222'
};

export const errorMessageStyle = {
    color: '#212222',
    fontWeight: '500',
    margin: '10px'
};

export const configIconStyle = {
    cursor: 'pointer',
    fontSize: '40px',
    color: '#212222'
};

// Visual Effects
export const gradientShadowStyle = {
    content: '""',
    position: "absolute",
    bottom: "-15px",
    left: "0",
    width: "100%",
    height: "4px",
    background: "linear-gradient(to right, #009ade, #643694, #ad275e, #ed1b2e)",
    zIndex: "-1"
};

export const boxColorMap = {
    0: 'rgba(185, 230, 148, 1)',
    1: 'rgba(206, 238, 181, 1)',
    2: 'rgba(148, 230, 149, 1)',
    3: 'rgba(181, 238, 182, 1)',
    4: 'rgba(165, 232, 185, 1)',
    5: 'rgba(174, 224, 133, 1)'
}

export const hashColor = (version) => {
    let length = version.length
    let hash = 0
    let count = 0

    if(length > 0){
        while(count < length){
            hash = (((hash << 5) - hash) + version.charCodeAt(count)) | 0
            count++
        }
    }
    return boxColorMap[hash%6]
}