import { CognitoAuth } from "amazon-cognito-auth-js";


const config = {
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    AppWebDomain: process.env.REACT_APP_COGNITO_LOGIN_APP_DOMAIN,
    TokenScopesArray: ["openid", "profile", "email"],
    RedirectUriSignIn: process.env.REACT_APP_COGNITO_CALLBACK_URL,
    RedirectUriSignOut: process.env.REACT_APP_COGNITO_LOGOUT_URL,
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
};

const auth = new CognitoAuth(config);

// This handler needs to be defined or else the Cognito library will throw NPEs.
auth.userhandler = {
    onSuccess: function (result) {},
    onFailure: function (err) {},
};

export function amrDevSignIn() {
    auth.getSession();
}

export function getIdToken() {
    return auth.getSignInUserSession().getIdToken().jwtToken;
}

export function isSignInRedirect() {
    return !!window.location.hash?.match("id_token")
}

export function handleSignInRedirect() {
    auth.parseCognitoWebResponse(window.location.href);
}

export function signOut() {
    auth.signOut();
}

export function isSignedIn() {
    return auth.isUserSignedIn();
}