import {Redirect, Route, useHistory} from "react-router-dom";
import {useEffect, useState} from "react";

import * as auth from "./Auth";
import FullPageSpinner from "../FullPageSpinner";


export const AuthLoginView = () => {
    const [_, forceRerender] = useState(0)

    useEffect(() => {
        if (auth.isSignedIn()) {
            return
        }

        if (auth.isSignInRedirect()) {
            auth.handleSignInRedirect().then(() => {
                // After tokens are retrieved (we are now signed in), re-render
                // this component which will redirect to the home page.
                forceRerender()
            });
        } else if (!auth.isSignedIn()) {
            auth.signIn();
        }
    }, [_]);

    if (auth.isSignedIn()) {
        return <Redirect to={{pathname: "/"}} />
    } else {
        return (
            <FullPageSpinner
                text="...authenticating"
                color="primary"
            />
        );
    }
};



function AuthenticatedRoute({ children, roles, ...rest }) {
    const history = useHistory();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.isSignedIn() ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/auth",
                            hash: history.location.hash,
                            search: window.location.search,
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default AuthenticatedRoute;