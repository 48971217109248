import React, { useEffect, useState } from 'react';

import AppConfigDialog from './AppConfigDialog';

import * as BoxStyles from './BoxStyles';
import { hashColor } from './BoxStyles';

import { Box, Button, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const envMap = {
    'amr_dev': 'https://dev.resmedmaskselector.com/',
    'amr_qa': 'https://pt.resmedmaskselector.com/',
    'amr_stg': 'https://stg.resmedmaskselector.com/',
    'amr_demo': 'https://demoamr.resmedmaskselector.com/',
    'amr_prd' : 'https://mask-selector.sds.resmed.com/',
    'eu_dev': 'https://deveu.resmedmaskselector.com/',
    'eu_demo': 'https://demoeu.resmedmaskselector.com/',
    'eu_prd': 'https://maskselector.resmed.eu/'
}

const EnvironmentBox = ({ url, token, setSelectedConfigValue, setErrorData, setErrorDialogOpen, env }) => {

    const [data, setData] = useState({})
    const [errorInfo, setErrorInfo] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [isOperational, setIsOperational] = useState(true)
    const [titleColor, setTitleColor] = useState('')
    const [isAppConfigOpen, setAppConfigOpen] = useState(false)

    useEffect(() => {
        getInfo(url, token)
            .then(data => {
                setIsLoading(false)
                if (data.status === 200) {
                    return data.json()
                }
                else {
                    throw new Error("HTTP error" + data.status);
                }
            })
            .then((data) => {
                if (checkDataEmpty(data)) {
                    setIsOperational(false);
                    setErrorInfo({
                        title: `${getErroredEnvironment(url)[0]} ${getErroredEnvironment(url)[1]}`,
                        error: 'Data received is empty or incomplete.',
                    });
                } else {
                    setData(data);
                    setIsOperational(true);
                    setTitleColor(hashColor(data.version));
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsOperational(false);
                const erroredEnvironment = getErroredEnvironment(url);
                const errorString = `Environment with the URL ${url} is not configured correctly. ${error.message}.`;
                setTitleColor('#E53935');
                setErrorInfo({
                    title: `${erroredEnvironment[0] || 'ERROR'} ${erroredEnvironment[1] || ''}`,
                    error: errorString,
                });
            });
    }, [url, token]);

    const checkDataEmpty = (data) => {
        return Object.keys(data).length === 0
    }

    const getErroredEnvironment = (url) => {
        let splitUrl = url.split(".")

        if (url === "https://api.resmedmaskselector.com/v2/info") {
            return ['PROD', 'Mask Selector']
        }
        else if (url === "https://api.eu.resmedmaskselector.com/v2/info"){
            return ['PROD', 'Mask Selector']
        }
        else if (url.includes("resmedmaskselector")){
            return [splitUrl[1], "Mask Selector"]
        }
        else if (url.includes("multiviewfacerecon")) {
            return [splitUrl[1], "Multiview Face Recon"]
        }

        return [splitUrl[1], splitUrl[2]]
    }

    const getInfo = (url, token) => {
        const headers = {
            "Accept": "application/json, text/plain, */*",
            "Accept-Language": "en-US,en;q=0.9",
            "Authorization": `Bearer ${token}`
        }

        return fetch(url, headers)
    }

    const clickConfigProperties = () => {
        setSelectedConfigValue(data);
        setAppConfigOpen(true);
    }

    const clickErrorProperties = () => {
        setErrorData(errorInfo);
        setErrorDialogOpen(true);
    }

    return (
        <>
            {isLoading ? (
                <Box style={BoxStyles.versionStyle}>
                    <Box sx={BoxStyles.loadingTitleBackgroundStyle}>
                        <Box sx={BoxStyles.inlineBoxStyle}>
                            <Typography sx={{ fontWeight: 'bold' }}>LOADING</Typography>
                        </Box>
                    </Box>
                </Box>
            ) : isOperational ? (
                <Box style={BoxStyles.versionStyle}>
                    <Box sx={{ ...BoxStyles.titleBackgroundStyle(isOperational), backgroundColor: titleColor }}>
                        <Box sx={BoxStyles.inlineBoxStyle}>
                            <Typography sx={{ fontWeight: 'bold' }}>
                                {data.environment ? data.environment.toUpperCase() : 'Error'}
                            </Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 'normal' }}>
                            {data.product_name ? data.product_name : 'Error'}
                        </Typography>
                    </Box>
                    <Box style={BoxStyles.centeredStyle}>
                        {envMap[env] && (
                            <a href={envMap[env]} target="_blank" rel="noreferrer" style={BoxStyles.linkStyle}>
                                <Button variant="contained" style={BoxStyles.buttonStyle}>
                                    Launch
                                </Button>
                            </a>
                        )}
                    </Box>
                    <Box style={BoxStyles.bottomLabelStyle}>
                        {data.app_config && (
                            <Box>
                                <SettingsIcon style={BoxStyles.configIconStyle} onClick={clickConfigProperties} />
                            </Box>
                        )}
                        <Box style={BoxStyles.versionTextStyle}>{data.version}</Box>
                    </Box>
                </Box>
            ) : (
                <Box style={BoxStyles.erroredStyle} onClick={clickErrorProperties}>
                    <Box sx={{ ...BoxStyles.titleBackgroundStyle(isOperational), backgroundColor: titleColor }}>
                        <Box sx={BoxStyles.inlineBoxStyle}>
                            <Typography sx={{ fontWeight: 'bold' }}>{errorInfo.title.split(' ')[0]}</Typography>
                        </Box>
                        <Typography sx={{ fontWeight: 'normal' }}>{errorInfo.title.split(' ').slice(1).join(' ')}</Typography>
                    </Box>
                    <Box style={BoxStyles.centeredStyle}>
                        <Button variant="contained" style={BoxStyles.errorButtonStyle}>
                            Prompt
                        </Button>
                    </Box>
                </Box>
            )}
            <AppConfigDialog
                open={isAppConfigOpen}
                onClose={() => setAppConfigOpen(false)}
                appConfigData={data}
                titleColor={titleColor}
            />
        </>
    )
}

export default EnvironmentBox