import * as auth from "./CognitoAuth";

export function signIn() {
    void auth.amrDevSignIn();
}

export function isSignInRedirect() {
    return auth.isSignInRedirect()
}

export async function handleSignInRedirect() {
    await auth.handleSignInRedirect();
}

export function signOut() {
    auth.signOut();
}

export function isSignedIn() {
    return auth.isSignedIn();
}

export function getIdToken() {
    return auth.getIdToken();
}
