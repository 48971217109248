import * as React from 'react';

import * as BoxStyles from './BoxStyles';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/src/styles/prism';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const AppConfigDialog = ({ onClose, appConfigData, open, titleColor }) => {
    const to_display = JSON.stringify(appConfigData, null, 2);

    return (
        <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="md" sx={BoxStyles.dialogBoxStyle}>
            <DialogTitle sx={{ ...BoxStyles.dialogTitleStyle(false), backgroundColor: titleColor }}>
                <Box sx={BoxStyles.alignDialogTitleStyle}>
                    <Box sx={BoxStyles.inlineBoxStyle}>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {appConfigData.environment?.toUpperCase()}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontWeight: 'normal' }}>
                        {appConfigData.product_name}
                    </Typography>
                </Box>
                <IconButton onClick={onClose} sx={BoxStyles.iconStyle}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Box ml="10px" mr="10px" sx={BoxStyles.dialogContentStyle}>
                <SyntaxHighlighter language="json" style={coldarkDark}>
                    {to_display}
                </SyntaxHighlighter>
            </Box>
        </Dialog>
    );
}

export default AppConfigDialog;