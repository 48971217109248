import React from "react";

import { Box, CircularProgress, Typography } from "@mui/material";

const Spinner = ({ color = "inherit", text }) => {

    return (
        <Box
            color="common.white"
            p={2}
            pt="25%"
            textAlign="center"
        >
            <CircularProgress color={color} />

            {text && (
                <Typography color={color} variant="h6">
                    {text}
                </Typography>
            )}
        </Box>
    );
};

export default Spinner;