import {Route, Router, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthenticatedRoute, {
    AuthLoginView,
} from "./components/authentication/AuthenticatedRoute";
import AppContainer from "./components/AppContainer";

function App() {
  return (

      <Router history={createBrowserHistory()}>
          <Switch>
              <Route exact path={"/auth"}>
                  <AuthLoginView />
              </Route>
              <AuthenticatedRoute exact path={"/"}>
                  <AppContainer />
              </AuthenticatedRoute>
          </Switch>
      </Router>
  );
}

export default App;
